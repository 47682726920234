var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Radio, FormLabel, RadioGroup, FormControl, FormHelperText, FormControlLabel, Tooltip, } from '@mui/material';
// translation
import { useLocales } from '../../locales';
export default function RHFRadioGroup(_a) {
    var row = _a.row, name = _a.name, label = _a.label, options = _a.options, spacing = _a.spacing, helperText = _a.helperText, disabled = _a.disabled, other = __rest(_a, ["row", "name", "label", "options", "spacing", "helperText", "disabled"]);
    var control = useFormContext().control;
    var labelledby = label ? "".concat(name, "-").concat(label) : '';
    var translate = useLocales().translate;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsxs(FormControl, __assign({ component: "fieldset" }, { children: [label && (_jsx(FormLabel, __assign({ component: "legend", id: labelledby, sx: { typography: 'body2' } }, { children: "".concat(translate(label)) }))), _jsx(RadioGroup, __assign({}, field, { "aria-labelledby": labelledby, row: row, value: field.value !== undefined && !Number.isNaN(field.value) && field.value, onBlur: field.onBlur }, other, { children: options.map(function (option) { return (_jsx(FormControlLabel, { disabled: disabled, value: option.value !== undefined &&
                                    !Number.isNaN(option.value) &&
                                    option.value.toString(), control: _jsx(Radio, {}), label: "".concat(translate(option.label)), sx: __assign({ '&:not(:last-of-type)': {
                                        mb: spacing || 0,
                                    } }, (row && {
                                    mr: 0,
                                    '&:not(:last-of-type)': {
                                        mr: spacing || 2,
                                    },
                                })) }, option.value !== undefined &&
                                !Number.isNaN(option.value) &&
                                option.value.toString())); }) })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) })))] })) })));
        } }));
}
